import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex flex-wrap showLinks justify-center" }
const _hoisted_2 = {
  class: "py-6 px-2 w-full sm:w-96 bg-white shadow",
  style: {"height":"34rem"}
}
const _hoisted_3 = { class: "text-center" }
const _hoisted_4 = { class: "text-center" }
const _hoisted_5 = ["href"]
const _hoisted_6 = { class: "text-center" }
const _hoisted_7 = ["href"]
const _hoisted_8 = { class: "text-center mt-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeader = _resolveComponent("SectionHeader")!
  const _component_ImageHolder = _resolveComponent("ImageHolder")!
  const _component_Press = _resolveComponent("Press")!
  const _component_ArticleContainer = _resolveComponent("ArticleContainer")!
  const _component_RemoteContent = _resolveComponent("RemoteContent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SectionHeader, { title: "Corpus Coranicum" }),
    _createVNode(_component_RemoteContent, { data: _ctx.info }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.info.payload, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.title,
              class: "w-96 bg-grey-light px-2 pt-4"
            }, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("h3", _hoisted_3, _toDisplayString(item.title), 1),
                _createElementVNode("p", _hoisted_4, _toDisplayString(item.subtitle), 1),
                _createElementVNode("a", {
                  href: 
              item.links[0].add_language
                ? `${_ctx.$i18n.locale}/${item.links[0].url}`
                : item.links[0].url
            
                }, [
                  _createVNode(_component_ImageHolder, {
                    imageUrl: _ctx.publicPath + 'img/' + item.image,
                    class: "px-4 h-48 text-center my-4 round-image"
                  }, null, 8, ["imageUrl"])
                ], 8, _hoisted_5),
                _createElementVNode("ul", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.links, (l) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: l.url
                    }, [
                      _createElementVNode("a", {
                        href: l.add_language ? `${_ctx.$i18n.locale}/${l.url}` : l.url
                      }, _toDisplayString(l.text), 9, _hoisted_7)
                    ]))
                  }), 128))
                ])
              ])
            ]))
          }), 128))
        ]),
        _createVNode(_component_ArticleContainer, { class: "my-6" }, {
          default: _withCtx(() => [
            _createElementVNode("h2", _hoisted_8, _toDisplayString(_ctx.$t('navigation.in_the_press')), 1),
            _createVNode(_component_Press, { class: "showLinks mx-auto my-3 max-w-screen-lg" })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data"])
  ], 64))
}