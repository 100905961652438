<template>
  <ul>
    <li>
      Spiegel Online (01.11.2007):
      <a
        href="http://www.spiegel.de/kultur/gesellschaft/religion-die-klimaforscher-des-korans-a-514628.html"
        >Die Klimaforscher des Korans</a
      >
    </li>
    <li>
      Rhein-Neckar-Zeitung (23.07.2014):
      <a
        href="http://www.rnz.de/wissen/wissenschaft-regional_artikel,-Die-heilige-Schrift-der-Muslime-_arid,7134.html"
        >Die heilige Schrift der Muslime</a
      >
    </li>
    <li>
      Islamiq (15.09.2014):
      <a
        href="http://www.islamiq.de/2014/09/15/angelika-neuwirth-ganz-klar-ein-missbrauch-des-islam/"
        >Interview mit Angelika Neuwirth: Ganz klar ein Missbrauch des Islam</a
      >
    </li>
    <li>
      Radio Vatican (27.09.2014):
      <a
        href="http://fr.radiovaticana.va/news/2014/09/27/michael_marx,_directeur_du_projet_corpus_coranicum/1107397"
        >Michael Marx, directeur du projet Corpus Coranicum</a
      >
    </li>
    <li>
      Universität Tübingen (10.10.2014):
      <a
        href="https://www.uni-tuebingen.de/aktuelles/pressemitteilungen/newsfullview-pressemitteilungen/article/raritaet-entdeckt-koranhandschrift-stammt-aus-der-fruehzeit-des-islam.html"
        >Rarität entdeckt: Koranhandschrift stammt aus der Frühzeit des Islam
      </a>
    </li>
    <li>
      BBC (09.12.2014):
      <a href="http://www.bbc.com/persian/world/2014/12/141209_sam_koran"
        >Interview mit Michael Marx</a
      >
    </li>
    <li>
      Der Standard (13.12.2014):
      <a
        href="http://derstandard.at/2000009325749/Was-die-Muslime-schon-immer-sagten"
        >Was die Muslime schon immer sagten</a
      >
    </li>
    <li>
      Mashregh News (20.12.2014):
      <a
        href="http://www.mashreghnews.ir/fa/news/365829/%D8%B1%D9%88%DB%8C-%D9%86%D8%B3%D8%AE%D9%87-%D8%A7%DB%8C-%D8%A7%D8%B2-%D9%82%D8%B1%D8%A2%D9%86-%DA%A9%D8%A7%D8%B1-%D9%85%DB%8C-%DA%A9%D9%86%DB%8C%D9%85-%DA%A9%D9%87-%D9%85%D8%B1%D8%A8%D9%88%D8%B7-%D8%A8%D9%87-40-%D8%B3%D8%A7%D9%84-%D9%BE%D8%B3-%D8%A7%D8%B2-%D9%88%D9%81%D8%A7%D8%AA-%D9%BE%DB%8C%D8%A7%D9%85%D8%A8%D8%B1-%D8%A7%D8%B3%D8%AA-%D8%AF%D8%A7%D9%86%D9%84%D9%88%D8%AF"
        >Interview mit Michael Marx</a
      >
    </li>
    <li>
      Le Monde (05.02.2015):
      <a
        href="http://www.lemonde.fr/religions/article/2015/02/05/nous-avons-trop-longtemps-neglige-le-travail-intellectuel-sur-le-coran-et-l-islam_4570413_1653130.html"
        >Nous avons trop longtemps négligé le travail intellectuel sur le Coran
        et l’islam</a
      >
    </li>
    <li>
      Der Tagesspiegel (02.04.2015):
      <a
        href="http://www.tagesspiegel.de/berlin/staatsbibliothek-zu-berlin-aelteste-bekannte-koran-handschriften-entdeckt/11593442.html"
        >Älteste bekannte Koran-Handschriften entdeckt</a
      >
    </li>
    <li>
      Frace TV Info (08.04.2015):
      <a
        href="http://www.francetvinfo.fr/societe/education/une-chaire-consacree-au-coran-pour-parler-de-son-histoire-que-certains-veulent-effacer_867461.html"
        >Une chaire consacrée au Coran pour "parler de son histoire, que
        certains veulent effacer"</a
      >
    </li>
    <li>
      Financial Times China (08.06.2015):
      <a href="http://www.ftchinese.com/story/001062395?full=y"
        >解读宗教经典的德国学者</a
      >
    </li>
    <li>
      Spiegel Online (22.07.2015):
      <a
        href="http://www.spiegel.de/wissenschaft/mensch/koran-eines-der-aeltesten-textfragmente-entdeckt-a-1044775.html"
        >Eines der ältesten Textfragmente des Koran entdeckt</a
      >
    </li>
    <li>
      Qantara (29.07.2015):
      <a
        href="https://de.qantara.de/content/berliner-islamwissenschaftlerin-neuwirth-erhaelt-hochschulpreis"
        >Berliner Islamwissenschaftlerin Neuwirth erhält Hochschulpreis</a
      >
    </li>
    <li>
      ISNA (16.11.2015):
      <a
        href="http://isna.ir/fa/news/94082515179/%D8%A7%D9%88%D9%84%DB%8C%D9%86-%D9%86%D8%B4%D8%B3%D8%AA-%D8%B9%D9%84%D9%85%DB%8C-%D8%A7%D9%84%DA%A9%D8%AA%D8%B1%D9%88%D9%86%DB%8C%DA%A9%DB%8C-%D9%85%D8%B9%D8%B1%D9%81%DB%8C-%D9%BE%D8%B1%D9%88%DA%98%D9%87-%DA%A9%D8%B1%D9%BE%D9%88%D8%B3"
        >Introduction of Corpus Coranicum Project in Iranian Ministry of
        Science, Research and Technology</a
      >
    </li>
    <li>
      Yeniasya (16.12.2015):
      <a
        href="http://www.yeniasya.com.tr/dunya/almanya-da-14-asirlik-kur-an-i-kerim-sayfalari-korunuyor_310040"
        >Almanya'da 14 asırlık Kur'an-ı Kerim sayfaları korunuyor</a
      >
    </li>
    <li>
      Deutschlandradio Kultur: Religionen (20.12.2015):
      <a
        href="http://www.deutschlandradiokultur.de/schatz-in-der-staatsbibliothek-die-aelteste.1278.de.html?dram:article_id=340380"
        >Dieter Wulf: "Die älteste Koranhandschrift liegt in Berlin"</a
      >
    </li>
    <li>
      sabah.com.tr (27.12.2015):
      <a
        href="https://www.sabah.com.tr/gundem/2015/12/27/hz-osmanin-emanetine-sahip-cikamadik"
        >Hz. Osman'ın emanetine sahip çıkamadık</a
      >
    </li>
    <li>
      IQNA (08.02.2016):
      <a
        href="http://www.iqna.ir/fa/news/3473978/%D8%AC%D8%A7%DB%8C-%D8%AE%D8%A7%D9%84%DB%8C-%D9%86%D8%B3%D8%AE-%D8%AE%D8%B7%DB%8C-%D9%82%D8%B1%D8%A2%D9%86%DB%8C-%D8%A7%DB%8C%D8%B1%D8%A7%D9%86-%D8%AF%D8%B1-%DA%A9%D9%88%D8%B1%D9%BE%D8%B3-%DA%A9%D9%88%D8%B1%D8%A7%D9%86%DB%8C%DA%A9%D9%88%D9%85"
        >Absence of Iranian Quranic manuscripts in Corpus Coranicum Project</a
      >
    </li>
    <li>
      Moshaf.org (08.02.2016):
      <a
        href="http://moshaf.org/news/10443/%D8%A8%D8%A7%D8%B2%D8%AF%DB%8C%D8%AF_%D8%B1%D8%A6%DB%8C%D8%B3_%D9%85%D8%A4%D8%B3%D8%B3%D9%87_%DA%A9%D9%88%D8%B1%D9%BE%D9%88%D8%B3_%DA%A9%D9%88%D8%B1%D8%A7%D9%86%DB%8C%DA%A9%D9%88%D9%85_%D8%A7%D8%B2_%D9%85%D8%B1%DA%A9%D8%B2_%D8%B7%D8%A8%D8%B9_%D9%88_%D9%86%D8%B4%D8%B1_%D9%82%D8%B1%D8%A2%D9%86_%D8%AC%D9%85%D9%87%D9%88%D8%B1%DB%8C_%D8%A7%D8%B3%D9%84%D8%A7%D9%85%DB%8C_%D8%A7%DB%8C%D8%B1%D8%A7%D9%86"
        >Visit of the Iranian Center for the Publication of the Qur'an</a
      >
    </li>
    <li>
      IQNA (10.02.2016):
      <a
        href="http://www.iqna.ir/fa/news/3474736/%D9%86%D8%B4%D8%B3%D8%AA-%D8%B3%D8%A7%D9%84%E2%80%8C%DB%8C%D8%A7%D8%A8%DB%8C-%D9%82%D8%B1%D8%A2%D9%86%E2%80%8C%D9%87%D8%A7%DB%8C-%D8%AE%D8%B7%DB%8C-%D8%A8%D9%87-%D8%B1%D9%88%D8%B4-%DA%A9%D8%B1%D8%A8%D9%86-14-%D8%A8%D8%B1%DA%AF%D8%B2%D8%A7%D8%B1-%D8%B4%D8%AF"
        >Carbon Dating of Old Quranic Manuscripts</a
      >
    </li>
    <li>
      Astan News (14.02.2016):
      <a
        href="http://news.aqr.ir/portal/home/?news/38388/662887/834954/%DA%A9%D8%AA%D8%A7%D8%A8%D8%AE%D8%A7%D9%86%D9%87-%D8%A2%D8%B3%D8%AA%D8%A7%D9%86-%D9%82%D8%AF%D8%B3-%D8%B1%D8%B6%D9%88%DB%8C-%D8%A2%DB%8C%D9%86%D9%87-%D9%87%D8%B2%D8%A7%D8%B1%D8%B3%D8%A7%D9%84%D9%87-%D8%AA%D9%85%D8%AF%D9%86-%D8%A8%D8%B4%D8%B1%DB%8C-%D8%A7%D8%B3%D8%AA"
        >Astan-e Quds-e Razavi Library Mirrors Thousand Years of Human
        Civilization</a
      >
    </li>
    <li>
      IQNA (21.02.2016):
      <a
        href="http://iqna.ir/fa/news/3476802/%D8%B6%D8%B1%D9%88%D8%B1%D8%AA-%D9%87%D9%85%DA%A9%D8%A7%D8%B1%DB%8C-%D8%A7%DB%8C%D8%B1%D8%A7%D9%86-%D8%A8%D8%B1%D8%A7%DB%8C-%D9%BE%DB%8C%D8%B4%D8%A8%D8%B1%D8%AF-%D8%B7%D8%B1%D8%AD%E2%80%8C%D9%87%D8%A7%DB%8C-%D8%AA%D8%AD%D9%82%DB%8C%D9%82%D8%A7%D8%AA%DB%8C-%D9%82%D8%B1%D8%A2%D9%86%DB%8C-%D8%AF%D8%B1-%D8%A7%D8%B1%D9%88%D9%BE%D8%A7"
        >The need for Cooperation to Promote the Quranic Research in Europe</a
      >
    </li>
    <li>
      IQNA (22.02.2016):
      <a
        href="http://www.iqna.ir/en/news/3459148/carbon-dating-scientific-way-of-verifying-old-quranic-manuscripts"
        >Carbon Dating, Scientific Way of Verifying Old Quranic Manuscripts
      </a>
    </li>
    <li>
      islam.ru (31.03.2016):
      <a href="http://islam.ru/content/nauka/46644"
        >Кто объяснит феномен Ефима Резвана?</a
      >
    </li>
    <li>
      Deutschlandfunk Kultur: Religionen (09.10.2016):
      <a
        href="http://www.deutschlandradiokultur.de/religionen-vom-9-oktober-2016-kloester-und-koran.1278.de.html?dram:article_id=367882"
        >Klöster und Koran</a
      >
    </li>
    <li>
      Potsdamer Neueste Nachrichten (21.12.2016):
      <a
        href="https://www.pnn.de/wissenschaft/die-schoenheit-der-suren/21379854.html"
        >Die Schönheit der Suren</a
      >
    </li>
    <li>
      Gießener Anzeiger (16.03.2017):
      <a
        href="http://www.giessener-anzeiger.de/lokales/stadt-giessen/nachrichten-giessen/berliner-islamwissenschaftlerin-angelika-neuwirth-in-giessen-mit-erwin-stein-preis-ausgezeichnet_17752140.htm"
        >Berliner Islamwissenschaftlerin Angelika Neuwirth in Gießen mit
        Erwin-Stein-Preis ausgezeichnet</a
      >
    </li>
    <li>
      Berliner Zeitung (23.03.2017):
      <a
        href="https://www.berliner-zeitung.de/kultur/glauben-seyran-ates-gruendet-in-berlin-eine-liberale-moscheegemeinde-26242870"
        >Seyran Ates gründet in Berlin eine liberale Moscheegemeinde
      </a>
    </li>
    <li>
      Indian Express (18.06.2017):
      <a
        href="http://indianexpress.com/article/lifestyle/books/the-sanaa-code-4709129/"
        >Decades after earliest Quran was discovered, scholars to share full
        text of the Sana’a manuscript</a
      >
    </li>
    <li>
      Berliner Zeitung (11.07.2017):
      <a
        href="https://www.berliner-zeitung.de/kultur/pergamon-museum-sonderausstellung-zeigt-schriften-und-schaetze-zum-koran-24075782"
        >Sonderausstellung zeigt Schriften und Schätze zum Koran
      </a>
    </li>
    <li>
      Aujourdhui (18.07.2017):
      <a
        href="http://aujourdhui.ma/emploi/formation/francophonie-un-marocain-prime-pour-la-meilleure-these"
        >Francophonie: Un Marocain primé pour la meilleure thèse!</a
      >
    </li>
    <li>
      Der Standard (17.01.2018):
      <a
        href="https://derstandard.at/2000072303681/Ist-der-Koran-nur-ein-riesiger-Schwindel"
        >Ist der Koran nur ein riesiger Schwindel?</a
      >
    </li>
    <li>
      tell me a history Podcast (30.03.2018):
      <a
        href="http://tellmeahistory.net/tmah003-der-koran-als-text-der-spaetantike/"
        >Der Koran als Text der Spätantike</a
      >
    </li>
    <li>
      Le Figaro (24.04.2018):
      <a
        href="http://www.lefigaro.fr/actualite-france/2018/04/24/01016-20180424ARTFIG00280-remi-brague-comment-8220contextualiser8221-la-parole-d-un-dieu-eternel.php"
        >Rémi Brague : «Le parallèle entre antijudaïsme musulman et antijudaïsme
        catholique est faux»</a
      >
    </li>
    <li>
      sonntag-sachsen.de (01.07.2018):
      <a href="https://www.sonntag-sachsen.de/2018/26/neues-licht-auf-den-koran"
        >Neues Licht auf den Koran</a
      >
    </li>
    <li>
      kumparan.com (19.11.2018):
      <a
        href="https://kumparan.com/bandungkiwari/tingginya-gairah-mempelajari-islam-di-jerman-mereka-kuasai-bahasa-indonesia-dan-arab-1542609900007253230"
        >Tingginya Gairah Mempelajari Islam di Jerman, Mereka Kuasai Bahasa
        Indonesia dan Arab</a
      >
    </li>
    <li>
      rochester.edu (12.12.2018):
      <a
        href="http://www.rochester.edu/newscenter/faculty-earn-neh-honors-for-religious-history-art-history-research-354532/"
        >Faculty earn NEH honors for religious history, art history research</a
      >
    </li>
    <li>
      IQNA (08.01.2019):
      <a href="http://iqna.ir/fa/news/3778923/"
        >Datierung von Koranhandschriften: Ein Thema in der westlichen
        Koranstudien</a
      >
    </li>
    <li>
      IQNA (12.02.2019):
      <a href="http://iqna.ir/fa/news/3788222/"
        >Die Rolle der Koranhandschriften bei der Analyse und Bewertung von
        Lesarten und Rechtschreibung des Korans</a
      >
    </li>
    <li>
      Islamiq.de (17.02.2019):
      <a
        href="http://www.islamiq.de/2019/02/17/der-historische-muhammad-s-in-der-islamischen-theologie/"
        >Der historische Muhammad (s) in der islamischen Theologie</a
      >
    </li>
    <li>
      IQNA (14.04.2019):
      <a href="https://iqna.ir/fa/news/3801955"
        >Entwicklung koranischer Bedeutungen durch Studium koranischer
        Handschriften</a
      >
    </li>
    <li>
      Mushaf Nusantara (31.10.2019):
      <a href="http://seamushaf.kemenag.go.id/#testi2"
        >Video Testimoni - Dr. Michael Marx</a
      >
    </li>
    <li>
      <a href="https://www.youtube.com/watch?v=P_1hroXG7yo&feature=youtu.be"
        >Interview mit Michael Marx (Al Jazeera)</a
      >
    </li>
    <li>
      <a
        href="https://www.faz.net/aktuell/wissen/geist-soziales/historisch-kritische-edition-des-korans-16859688.html"
        >Bericht in der FAZ (18.07.2020)</a
      >
    </li>
  </ul>
</template>
